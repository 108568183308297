.App {
  text-align: center;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
}

.App-header {
  background-color: #202020;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  text-align: left;
  padding: 18px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.App-header-copy {
  padding: 0px 12px;
}

.App-header-copy h1,
.App-header-copy p {
  padding: 0;
  margin: 0;
}

.logoLink {
  border-radius: 12px;
  overflow: hidden;
  height: 48px;
  width: 48px;
}

.logoLink img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.storeLink {

}

.storeLink-wrap {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

section .sectionBg {
  position: absolute;
  left: 50%;
  right: auto;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 300%;

  transform: rotate(4deg) translate3d(-50%, 0, 0);
}

section .sectionBg:nth-child(odd) {
  /* transform: rotate(2deg) translate3d(-50%, 0, 0); */
}

section img {
  width: 40%;
  object-fit: contain;
  order: 0;
  z-index: 2;
}

section .copy {
  order: 1;
  color: #fff;
  z-index: 2;
}

section .copyContent {
  text-align: left;
  width: 300px;
  transform: translate3d(-80px, 0, 0);
}

section.left .copyContent {
  transform: translate3d(80px, 0, 0);
}

section .copy .copyContent h3,
section .copy .copyContent p {
  padding: 0;
  margin: 0;
}

section .copy .copyContent h3 {
  font-size: 36px;
}

section .copy .copyContent p {
  padding: 6px 0 0 0;
  font-size: 18px;
}

section.left .sectionBg {
  /* transform: rotate(-2deg) translate3d(-50%, 0, 0); */
}

section.left img {
  order: 1;
}

section.left .copy {
  order: 0;
}

footer {
  background: #292929;
  padding: 36px;
  color: #fff;
  font-size: 12px;
}

@media (max-width: 768px) {
  section {
    flex-direction: column;
    padding-bottom: 32px;
    min-height: calc(100vh - 100px);
  }

  section .copyContent,
  section.left .copyContent {
    transform: translate3d(0, -32px, 0);
  }

  section img, section.left img {
    order: 0;
    width: 100%;
  }

  section .copy, section.left .copy {
    order: 1;
  }

  section .copy .copyContent h3 {
    font-size: 24px;
  }

  section .copy .copyContent p {
    font-size: 16px;;
  }
}
